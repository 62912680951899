<template>
  <div class="video-html">
    <video
      ref="videoElement"
      :src="props.videoUrl"
      :controls="props.controls"
      :muted="props.muted"
      :loop="props.loop"
      playsinline
      :autoplay="props.autoplay"
      :preload="props.preload"
    />
    <!-- :preload="props.preload" -->
  </div>
</template>

<script>
export default {
  name: 'WidgetVideo',
}
</script>

<script setup>
const props = defineProps({
  videoUrl: String,
  autoplay: Boolean,
  muted: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: String,
    default: 'none',
  },
  controls: {
    type: Boolean,
    default: false,
  },
  loop: Boolean,
  playInViewport: {
    // Se true e cambio tab del browser il video si stoppa
    type: Boolean,
    default: false,
  },
  forcePlay: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['play', 'pause'])

const videoElement = ref(null)
let observer = null

onMounted(() => {
  if (props.playInViewport) {
    playerObserver()
  }
})

watch(
  () => props.playInViewport,
  (playInViewport) => {
    if (playInViewport) {
      playerObserver()
    }
  },
)

watch(
  () => props.forcePlay,
  (forcePlay) => {
    var playPromise = videoElement.value?.play()
    if (forcePlay) {
      videoElement.value?.play()
    } else {
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            videoElement.value?.pause()
          })
          .catch((error) => {
            console.log('error watch ')
            console.log(error)
          })
      }
    }
  },
)

onUnmounted(() => {
  // Disconnect the observer when the component unmounts
  if (observer) {
    observer.disconnect()
  }
})

const playerObserver = () => {
  observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        var playPromise = entry.target.play()
        // If the video is visible in the viewport and autoplay is not prevented
        if (entry.isIntersecting) {
          emit('play')
          entry.target.play()
        } else {
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                emit('pause')
                entry.target.pause()
              })
              .catch((error) => {
                console.log('error ovserver ')
                console.log(error)
                // Auto-play was prevented
                // Show paused UI.
              })
          }
        }
      })
    },
    {
      threshold: 0.1, // trigger when at least 50% of the video is visible
    },
  )

  observer.observe(videoElement.value)

  // Se la tab non è attiva stoppo il vidoe altrimenti lo faccio ripartire
  document.addEventListener('visibilitychange', () => {
    var playPromise = videoElement.value?.play()
    if (document.hidden) {
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            videoElement.value?.pause()
          })
          .catch((error) => {
            console.log('error promise in listener visibility ')
            console.log(error)
            // Auto-play was prevented
            // Show paused UI.
          })
      }
    } else {
      videoElement.value?.play()
    }
  })
}

defineExpose({
  videoElement,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
